body {
    background-color: var(--background-color-main-design);
}
.login-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding:1.5rem;
    font: var(--font-regular-main-design);
    font-size: var(--font-fourteen);
    color: var(--text-color-over-overlay-main-design);
}

.login-page-container {
    width: 100%;
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
}

.login-skip-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.login-page-wrapper.rtl .login-skip-container {
    justify-content: flex-start;
}

.login-skip-link {
    color: var(--text-color-over-overlay-main-design);
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
}

.login-page-wrapper.rtl .login-skip-link {
    flex-direction: row-reverse;
}

.login-skip-arrow {
    margin-top:5px;
}

.login-page-wrapper.rtl .login-skip-arrow {
    margin-left: 5px;
}

.login-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.login-title{
    font-size: var(--font-thirty);
    margin-bottom: 12px;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

.login-form-input-wrapper {
     display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 19.125rem;
    max-width: 100%;
    height: 56px;
}

.login-form-input-error {
    color: red;
    padding: 0;
    font-size: var(--font-eleven);
    margin: 6px 0 0 6px;
    align-self: flex-start;
    position: absolute;
    top:54px;
    left:4px;
}

/* increase the input padding for phones screens larger than 320px */
@media screen and (min-width:320px){
    .login-phone-input{
        padding-left: 3.125rem;
    }
}

.login-next-btn {
    width: 19.125rem;
    max-width: 97%;
    height: 56px;
    border-radius: 12px;
    background-color: var(--main-color-main-design);
    color: var(--text-color-over-main-color-main-design);
    margin:4rem 0 1rem 0;
    font: var(--font-regular-main-design);
    font-size: var(--font-sixteen);
}

.login-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-top: 1rem;
}

.login-other-logins {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.login-other-media-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:2.7rem 0 3rem 0;
}

.login-other-media-icon-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    padding:.5rem;
    cursor: pointer;
    margin:0 5px;
}

.login-other-media-icon-container img {
    width: 100%;
    object-fit: contain;
}

.login-other-media-icon-container .login-google-img{
    width: 130%;
}

.login-footer-create-account-container{
    display: flex;
}

.login-create-account-link {
    font: var(--font-bold-main-design);
    color:var(--main-color);
    margin-left: 4px;
}

.login-page-wrapper footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color-main-design);
    font: var(--font-semibold-main-design);
    margin-top: 0.5rem;
}

.login-translation-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:var(--text-color-over-overlay-main-design);
}

.login-translation-icon-container {
    margin: 5px 10px 0 0 ;
    color: var(--icons-color-over-overlay-main-design);
}

body[dir="rtl"] .login-translation-icon-container {
    margin: 10px 0 0 10px;
}